<template>
    <main>
        <header
            class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
        >
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"></div>
                                Factura de Eventos
                            </h1>
                        </div>
                        <div class="col-12 col-xl-auto mb-3">
                            <button class="btn btn-success shadow-sm" @click="guardar()">
                                <i class="far fa-save text-white-50"></i> Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-6 offset-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="small">Fecha Inicio</label>
                                <input type="date" class="form-control form-control-sm" v-model="fecha_inicio">
                            </div>
                            <div class="form-group">
                                <label class="small">Fecha Final</label>
                                <input type="date" class="form-control form-control-sm" v-model="fecha_final">
                            </div>
                            <div class="form-group">
                                <label class="small">Eps</label>
                                <v-select-eps mode="object" v-model="eps"></v-select-eps>
                            </div>
                            <div class="form-group">
                                <label class="small mb-1">Contrato <small>(*)</small></label>
                                <select
                                    name="contratos"
                                    id="contratos"
                                    class="form-control form-control-sm"
                                    v-model="contrato"
                                >
                                    <option selected disabled>
                                        -- Seleccione una opcion --
                                    </option>
                                    <option
                                        v-for="contrato in eps.contratos"
                                        :key="contrato.id"
                                        :value="contrato"
                                    >
                                        {{ contrato.codigo }} - {{ contrato.descripcion }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary" @click="generate()">Generar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import VSelectEps from "../../../../components/common/VSelectEps";
export default {
    components: {VSelectEps},
    data (){
      return {
        fecha_inicio : '',
        fecha_final : '',
        eps : {},
        contrato : ''
      }
    },
    methods : {
        generate(){
            let url = `/api/rips/generate?fecha_inicio=${this.fecha_inicio}&fecha_fin=${this.fecha_final}&eps=${this.eps.id}&contrato=${this.contrato.id}`;
            window.open(url, '_blank');
        }
    }
}
</script>

